
.sec{
    margin: 30px 0px;
}

.qoutebox{
    display: flex;
    padding: 0px;
    gap: 20px;
}

.qoute-box1{
    border: 1px solid #16796e;
    padding: 25px;
    border-radius: 20px;
}

@media (max-width: 768px){
    .qoutebox{
        flex-direction: column;
    }
}
*{
    box-sizing: border-box;
}

/* Assuming you're using NavHashLink inside a p tag or another container */
p .nav-link {
    color: #3498db; /* Link color */
    text-decoration: none; /* Remove underline */
  }
  
  p .nav-link:hover {
    color: #2980b9; /* Color on hover */
    text-decoration: underline; /* Underline on hover */
  }
  

body {
    max-width: 100%;
    margin: 0 auto;
}
    

html {
    scroll-behavior: smooth;
  }
  

.container{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.container{
    width: 100%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
}

h1, h2, h3{
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

table, th, td {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  p, li, span, a {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }


.border-btn{
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: transparent;
    color: #16796e;
    border: 1px solid #16796e;
    border-radius: 20px;
}

.border-btn{
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: transparent;
    color: #16796e;
    border: 1px solid #16796e;
    border-radius: 20px;
    transition: transform 0.5s  ease;
}

.border-btn:hover{
    background-color: #16796e;
    color: #fff;
    transform: translateY(-5px);
}

.title-wrapper{
    
    max-width: 650px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
}

.section-title{
    padding: 5px;
    margin-top: 20px;
    font-size: 32px !important;
    line-height: 1.2;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: #16795e !important;
    /* background:#16795e; */
    border-radius: 20px;
    /* -webkit-text-fill-color: transparent; */
    margin-bottom: 15px;
}


/* .separator {
    width: 50px;
    height: 2px;
    background-color: #16795e !important;
    margin: 20px auto 0;
} */

@media screen and (max-width: 768px){
    .title-wrapper{
        margin-bottom: 15px;
        /* padding: 15px; */
    }
 .section-title{
    font-size: 1.6rem;
 }
}
/* Hero Section*/
/* 
.hero-section{
    position: relative;
    background-image: linear-gradient(180deg, rgba(94, 94, 94, 0.38), rgba(94, 94, 94, 0.38));
    background-repeat: no-repeat;
}

.hero-wrapper{
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    min-height: 100vh;
    padding-top: 120px;
    padding-bottom: 120px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items:center ;
}

.hero-container{
    width: 100%;
    max-width: 1000px;
}

.hero-content{    
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    padding: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;

}

.hero-h1{
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 3.9em;
    line-height: 120%;
}

.hero-p{
    color: rgb(255, 255, 255);
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: inline-block;
    margin-top: 35px;
    margin-bottom: 0px;
    padding-bottom: 35px;
    font-size: 1.5em;
    line-height: 130%;
}

.btn.ghost{
    border-color:#fff ;
    background-color: transparent;
}

.btn{
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.btn {
    padding: 15px 37px;
    border: 1px solid #000;
    border-radius: 8px;
    background-color: #000;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.w-button {
    display: inline-block;
    padding: 9px 15px;
    background-color: #3898EC;
    color: white;
    /* border: 0; 
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;
}


.wrapper-scroll {
        position: absolute;
        left: 0%;
        top: auto;
        right: 0%;
        bottom: 0%;
        text-align: center;
    }

.scroll-button-wrapper {
    width: 45px;
    height: 45px;
    margin-bottom: 20px;
    border-style: solid;
    border-width: 1px;
    border-color: #fff;
    border-radius: 360px;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
}

.w-inline-block {
    max-width: 100%;
    display: inline-block;
    width: 50px;
}
img{
    padding-top: 5px;
    padding-bottom: 5px;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    border: 0;
}
.scroll-button-wrapper img {
    width: 50%; 
     vertical-align: middle;
    display: inline-block;
    border: 0;
} */

p a {
    color: #16795e; /* Link color */
    text-decoration: underline; /* Remove underline */
    font-weight: bold; /* Bold text */
    transition: color 0.3s; /* Smooth color transition */
  }

p a:hover{
color: black;
}

.section-title-2{
    padding-top: 30px;
    margin-top: 0;
    font-size: 32px !important;
    line-height: 1.2;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: #16795e;
    /* background: #16795e; */
    border-radius: 20px;
    /* -webkit-text-fill-color: transparent; */
    margin-bottom: 15px;
}


@media screen and (max-width: 768px){
    .section-title-2{
        font-size: 25px !important;
    }

    .section-title{
        font-size: 25px !important;
    }
}


/* Product banner style */
.banner-section {
    position: relative;
    height: 60vh;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    padding: 0px;
    margin: 20px 0px;
    /* box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4); Dark overlay */
  }


  @media (min-width: 768px) and (max-width: 1024px) {
    .banner-section {
        height: 50vh; /* Adjust height for tablets */
        padding: 0 20px; /* Add some padding for tablets */
        background-size: cover;
    }
}

/* Mobile styles */
@media (max-width: 767px) {
    .banner-section {
        height: 40vh; /* Adjust height for mobile */
        padding: 0 15px; /* Add padding for smaller screens */
        background-size: cover;
        margin: 10px 0; /* Reduce margin on mobile */
    }
}
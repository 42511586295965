/* Glassy Form Styles */

.form-container{
    /* background-color: rgb(22, 121, 110, 0.31); */
    border-radius: 20px;
    margin: 30px 0px;

}
.advanced-form {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: rgb(255, 255, 255);  /* Semi-transparent white background */
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px); /* Glass effect */
    border: 1px solid rgba(255, 255, 255, 0.3); /* Subtle border */
    position: relative; /* To ensure message displays properly */
    z-index: 1; 
    /* To place form above background */
}
.form-row {
    display: flex;
    gap: 20px; /* Space between inputs in the same row */
    margin-bottom: 20px; /* Space below each row */
}

.form-group {
    flex: 1; /* Make each input take up an equal amount of space in the row */
}
/* Glassy Effect */
.advanced-form::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1); /* Glass effect overlay */
    border-radius: 12px;
    z-index: -1;
}

/* Background with Gradient */
.form-container {
    background-attachment: fixed; /* Keep the gradient fixed */
    background-size: cover; /* Cover the entire viewport */
    font-family: Arial, sans-serif;
    color: #333;
}

/* Form Groups */
.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid rgba(22, 121, 110, 0.4); /* Border with theme color */
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.3); /* Light background for inputs */
    color: #333;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
    border-color: #16796e; /* Highlight border with theme color */
    outline: none;
}

/* Textarea Specific Styles */
.form-group textarea {
    height: 120px;
    resize: vertical;
}

/* Error Styles */
.error {
    color: #d9534f;
    font-size: 14px;
}

/* Button Styles */
button {
    background-color: #16796e; /* Theme color for button background */
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover {
    background-color: #135c50; /* Darker shade of theme color on hover */
    transform: scale(1.05); /* Slight scale on hover */
}

button:active {
    background-color: #0f473b; /* Even darker shade of theme color when active */
}

/* Message Styles */
.message {
    margin-top: 20px;
    padding: 20px;
    border-radius: 8px;
    font-size: 18px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.9);
    color: #333;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px); /* Slight blur for the message */
}

.message.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.message.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}



@media (max-width: 768px) {
    .form-row {
        flex-direction: column;
        gap: 15px;
    }
}

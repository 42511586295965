.tab-sec {
    margin:0;
    /* Removed background */
    background: transparent; /* Set background to transparent */
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    /* box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); */
    padding-left: 10px;
    padding-right: 10px;
}

.tab-sec::before,
.tab-sec::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    opacity: 0.6;
    transform: scale(1);
    background: rgba(255, 255, 255, 0.08);
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.2),
                0 0 30px rgba(255, 255, 255, 0.2);
    transition: transform 0.5s ease;
}

.tab-sec::before {
    width: 300px;
    height: 300px;
    top: -50px;
    left: -100px;
}

.tab-sec::after {
    width: 250px;
    height: 250px;
    bottom: -60px;
    right: -80px;
}

.tab-sec:hover::before,
.tab-sec:hover::after {
    transform: scale(1.2);
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.w-tabs {
    position: relative;
    display: flex;
    flex-direction: column;
}

.w-tabs::before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2;
}

.tabs-menu {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.w-tab-menu {
    position: relative;
}

.w-tab-link {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    padding: 5px 0px;
    font-size: 14px;
    cursor: pointer;
    color: #000;
    background-color: transparent; /* Removed background color */
    font-weight: 600;
    margin: 0px 3px;
    text-align: center; /* Remove padding here to avoid conflicts */
    flex: 1; /* Distribute space evenly among tabs */
}

.tabm {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 49px;
    border-radius: 30px;
    font-size:14px;
    margin: 0 3px;
    padding: 8px 12px;
    font-weight: bold;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; 
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsla(0, 0%, 86.7%, 0.35);
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-top: 16px;
    width: auto; /* Auto width to fit content */
    max-width: 100%; /* Ensure the tab doesn't overflow */
    flex: 1; /* Distribute space evenly among tabs */
    box-sizing: border-box; /* Include padding in width/height */
}

.tabm.w--current {
    height: 50px;
    background-color: #16796e;
    color: #fff;
}

.w-inline-block {
    max-width: 100%;
    display: inline-block;
}

.w-tab-content {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 20px;
}

.w-tabs:after {
    clear: both;
}

.w-tabs:before, .w-tabs:after {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2;
}

/* Container for tab content */
.tabs-content {
    position: relative; /* Keep relative positioning */
    padding: 0px; /* Padding around content */
    border-radius: 10px; /* Rounded corners */
    overflow: hidden; /* Prevent content overflow */
    /* background: rgba(255, 255, 255, 0.2);  Transparent glass effect 
    backdrop-filter: blur(10px);  Apply blur effect for glassiness  */
    /* border: 1px solid rgba(255, 255, 255, 0.3); Subtle border to match the transparency */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.0);
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Blur effect for glass */
}

/* .tab-content::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(22, 121, 110, 0.2), rgba(255, 255, 255, 0.2));
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 20px;
}  */

.tab-content::before {
    opacity: 1;
}

/* .tab-content:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
} */

/* Tab pane styling */
.w-tab-pane {
    opacity: 0; /* Initially hidden */
    /* transform: translateY(20px); Initial position for smooth transition
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out; Smooth transition */
    position: absolute; /* Use absolute positioning for overlay effect */
    width: 100%; /* Ensure full width */
    height: 100%; /* Ensure full height */
    top: 0; /* Align with parent container */
    left: 0; /* Align with parent container */
    overflow: hidden; /* Ensure no overflow */
}

/* Active tab pane styling */
.w--tab-active {
    opacity: 1; /* Make active tab pane visible */
    transform: translateY(0); /* Move to the normal position */
    position: relative; /* Reset positioning for active tab pane */
}

/* Optional: Fade in effect for smoother appearance */
.w--tab-active {
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.tab-content p {
    font-size: 17px;
    text-align: center;
    color: #333 !important;
}

.w-layout-grid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-rows: auto auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    padding: 20px; /* Add padding around the grid */
}

.grid {
    /* margin-top: 75px; */
    grid-column-gap: 10px;
    grid-template-columns: repeat(3, 1fr); /* Responsive columns */
    grid-auto-rows: 200px; /* Uniform height for grid items */
    gap: 20px; /* Increase gap between items */
}

.img-grid {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 200px;
    filter: saturate(0%);
    transition: all 200ms ease;
    object-fit: cover;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.img-grid:hover {
    filter: saturate(100%);
    transform: scale(1.05);
}

.img-lb {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.tab-content p {
    color: #fff;
}

@media (max-width: 1024px) {
    .grid {
        grid-template-columns: repeat(2, 1fr); /* 2 columns on tablets */
    }
}

@media screen and (max-width: 768px) {
    .tabs-menu {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .tabm {
        width: 100%;
        height: auto;
        margin-right: 0px;
        margin-bottom: 10px;
        margin-left: 0px;
        padding-top: 11px;
        padding-bottom: 11px;
    }
    .grid {
        grid-template-columns: (2, 1fr); /* 1 column on mobile devices */
        grid-auto-rows: auto;
    }
    .tabs-content {
        margin: 5px;
        padding: 20px;
    }
}

.column-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px;
  background: linear-gradient,(135deg, rgba(22,121,110,0.8) 0% rgba(22,121,110,0.5) 100%);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  /* box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2); */
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.con, .con1 {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.con1{
  flex-direction: row-reverse;
}
.con:hover, .con1:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
}

.col-1 {
  flex: 1;
  padding: 20px;
  max-width: 600px;
  color: #fff;
}

.service-h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
  color: #16795e;
  border-radius: 20px;
  text-align: center;
  font-weight: 700;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
}

.service-p {
  font-size: 1.25rem;
  line-height: 1.8;
  color: rgba(0, 0, 0, 0.8);
  text-align: left;
  margin-bottom: 15px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

.col2, .col3 {
  flex: 1.5;
  text-align: center;
  position: relative;
}

.col2 img, .col3 img {
  max-width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.col2 img:hover, .col3 img:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .con, .con1 {
      flex-direction: column-reverse;
      text-align: center;
  }

  .col2, .col3 {
      margin-bottom: 20px;
  }

  .service-h2 {
      text-align: center;
  }

  .service-p {
      text-align: center;
  }
}

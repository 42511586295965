/* src/pages/PrivacyPolicy.css */
.policy-sec{
   margin-top: 60px;
}


.privacy-policy-page {
    padding: 40px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: 'Poppins', sans-serif;
    color: #333;
  }
  
  .privacy-policy-page h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #16796e;
  }
  
  .privacy-policy-page h2 {
    font-size: 1.8rem;
    margin-top: 30px;
    color: #16796e;
  }
  
  .privacy-policy-page h3 {
    font-size: 1.4rem;
    margin-top: 20px;
    color: #16796e;
  }
  
  .privacy-policy-page p {
    font-size: 1.1rem;
    margin-bottom: 15px;
    line-height: 1.6;
  }
  
@media (min-width: 769px) and (max-width: 1024px) {
    .navBar {
        display: flex;
        position: fixed;
        top: 0px;
        left: 0;
        right: 0;
        background-color: rgba(22, 121, 110, 0.9);
        width: 100%;
        height: 100vh;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px;
        box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
        transform: translateY(-100%);
        transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
        opacity: 0;
        backdrop-filter: blur(10px);
        z-index: 1000;
      }
    
      .navBar.nav-open {
        transform: translateY(0);
        opacity: 1;
        z-index: 1001;
      }
    
      .navList {
        display: flex;
        flex-direction: column;
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
      }
    
      .navItems {
        margin: 20px 0;
        width: 100%;
      }
    
      .navLinks {
        font-size: 1.5em;
        width: 100%;
        padding: 15px;
        text-align: center;
        border-bottom: 1px solid #ddd;
        transition: background-color 0.3s;
        color: #fff;
      }
    
      .navLinks:hover, .navLinks:focus {
        color: #333;
      }
      .navLinks.active{
        color: #333;
      }
      .hashLink {
        font-size: 1.5em;
        width: 100%;
        padding: 15px;
        text-align: center;
        border-bottom: 1px solid #ddd;
        transition: background-color 0.3s;
        color: #fff;
      }
    
      .hashLink:hover, .navLinks:focus {
        color: #333;
      }
    
      .hamburger {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 30px;
        right: 20px;
        z-index: 1002;
      }
      .logo{
        font-size: 1.5rem;
      } 
    
      /* Hide hamburger when menu is open */
      .hamburger.open {
        display: none;
      }
    
      .hamburger .bar {
        background-color: #16796e;
        height: 2px;
        width: 30px;
        margin: 4px 0;
        transition: 0.4s;
      }
    
      /* Cancel button */
      .cancelButton {
        align-self: flex-end;
        font-size: 1.5em;
        color: #fff;
        background: transparent;
        border: none;
        cursor: pointer;
        padding: 10px;
        transition: color 0.3s;
      }
    
      .cancelButton:hover {
        color: #0f4c4c;
      }
    }
    
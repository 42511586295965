/* General Styles */


/* Section Styles */
#brands {
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 80px 40px;
  /* box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); */
  position: relative;
}

/* Tabs Menu */
.tabs-menu {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.brand-tab {
  position: relative;
  padding: 16px 28px;
  cursor: pointer;
  color: #444;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(8px);
  font-weight: 600;
  margin: 0 10px;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.brand-tab:hover, .brand-tab.w--current {
  background-color: rgba(22, 121, 110, 0.8);
  color: #fff;
  transform: translateY(-3px);
}

/* Tabs Content */
.w-tab-content {
  position: relative;
  overflow: hidden;
}

/* Carousel Styles */
.brands-wrapper {
  overflow: hidden;
}

.brands-grid {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}

.w-dyn-items {
  display: flex;
}

.owl-carousel .owl-stage {
  display: flex;
  align-items: center;
}

.owl-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.owl-carousel .owl-item img {
  width: 180px;
  height: 60px;
  object-fit: contain;
  display: block;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0.8;
  border-radius: 10px;
}

.owl-carousel .owl-item img:hover {
  transform: scale(1.05);
  opacity: 1;
}

.brand-image {
  max-width: 350px;
  height: auto;
  opacity: 0.8;
  /* border: 2px solid rgba(22, 121, 110, 0.6); */
  /* background: rgba(216, 212, 212, 0.7); */
  transition: all 200ms ease;
}

/* Navigation Arrows */
.owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.owl-prev, .owl-next {
  background-color: rgba(0, 123, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.owl-prev:hover, .owl-next:hover {
  background-color: rgba(0, 86, 179, 0.8);
}

/* Dots Navigation */
.owl-dots {
  text-align: center;
  margin-top: 20px;
}

.owl-dot {
  background: rgba(0, 123, 255, 0.8);
  border-radius: 50%;
  width: 12px;
  height: 12px;
  display: inline-block;
  margin: 0 7px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.owl-dot.active {
  background: rgba(0, 86, 179, 0.9);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .tabs-menu {
    flex-direction: column;
  }

  .brand-tab {
    margin: 8px 0;
    width: 100%;
  }

  .owl-nav {
    flex-direction: column;
    width: auto;
    top: 10px;
  }

  .owl-prev, .owl-next {
    width: 35px;
    height: 35px;
    font-size: 1.2rem;
  }

  .owl-carousel .owl-item img {
    width: 90px;
    height: 100px;
    padding: 20px;
  }

  .brands-wrapper {
    padding: 0 15px;
  }
}

/* src/components/Testimonial.css */
.testimonial-section {
    padding: 60px 20px;
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.testimonial-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    max-width: 1200px;
}

.testimonial-card {
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: scale(1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testimonial-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.testimonial-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid #16796e; /* Optional border for extra style */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.testimonial-content {
    padding: 30px 20px 20px;
    text-align: center;
}

.testimonial-text {
    font-size: 1rem;
    color: #333;
    margin-bottom: 15px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    line-height: 1.6;
}

.testimonial-name {
    font-size: 1.1rem;
    color: #16796e;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-weight: 600;
    margin: 5px 0;
}

.testimonial-position {
    font-size: 0.9rem;
    color: #666;
    font-family: 'Helvetica Neue', Arial, sans-serif;
}

@media (max-width: 768px) {
    .testimonial-container {
        flex-direction: column;
    }
}

@media (min-width: 769px) and (max-width: 1024px){
    .testimonial-container{
        flex-wrap: nowrap !important;
    }
     /* six box at homepage*/
    .servhol{
        height: 500px !important;
    }
 /* two section text and image layout*/
    .con{
        flex-direction: column-reverse;
    }
     .con1{
        flex-direction: column-reverse !important;
     }

 /* product grid layout*/
    .p-box{
        flex-basis: calc(100% - 30px) !important;
        max-width: calc(100% - 30px) !important;
    }
    /* product category layout*/
    .product-box{
        flex: 0 0 100% !important;
    }
    /* service started three box*/
    .service-wrapper{
        height: 500px !important;
    }
}
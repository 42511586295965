/* General Styles */
.services {
  padding: 60px 0;
  background-color: #f9f9f9;
}

/* .title-wrapper {
  text-align: center;
  margin-bottom: 40px;
}

.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin: 0;
} */

/* .separator {
  width: 80px;
  height: 4px;
  background-color: #007bff;
  margin: 20px auto 0;
} */

/* Row and Column */
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
}

.col-lg-4 {
  width: calc(33.333% - 30px);
  margin-bottom: 30px;
}

/* Service Holder */
.servhol {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 350px ;
  text-align: center;
  padding: 20px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
}

.servhol:hover {
  transform: translateY(-10px);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.3);
  background: rgba(255, 255, 255, 0.35);
}

/* Service Image */
.serholimg img {
  width: 250px;
  height: 70px;
  object-fit: contain;
  margin-bottom: 15px;
  border-radius: 15px;
  transition: transform 0.3s ease;
}

.servhol:hover .serholimg img {
  transform: scale(1.1);
}

/* Service Content */
.colmeents h3 {
  font-size: 1.75rem;
  font-weight: 600;
  color: #65796e;
  margin: 10px 0;
}

.colmeents p {
  font-size: 1rem;
  color: #666;
  margin: 0;
  line-height: 1.6;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .col-lg-4 {
    flex: 0 0 100%;
    width: 80%;
  }

  .row {
    gap: 20px;
  }

  .servhol {
    padding: 15px;
    border-radius: 20px;
  }

  .serholimg img {
    width: 200px;
    height: 60px;
  }

  .section-title {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .servhol {
    padding: 10px;
  }

  .serholimg img {
    width: 150px;
    height: 50px;
  }

  .colmeents h3 {
    font-size: 1.5rem;
  }

  .colmeents p {
    font-size: 0.875rem;
  }
}

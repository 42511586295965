/* General Hero Section */
.hero-section {
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
}

/* Glassmorphism Effect */
.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(0px);
}

/* Hero Wrapper */
.hero-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 0 20px;
    z-index: 1;
}

.hero-container {
    text-align: center;
}

/* Hero Content */
.hero-h1 {
    color: #fff;
    font-weight: 700;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 4.5em;
    line-height: 1.2;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
}

.hero-p {
    color: #e0e0e0;
    font-size: 1.8em;
    line-height: 1.5;
    margin-bottom: 40px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

/* Button Design */
.btn.glass-btn {
    background: rgba(101, 121, 110, 0.3); /* Applied theme color with transparency */
    border: 1px solid rgba(101, 121, 110, 0.5); /* Applied theme color */
    border-radius: 10px;
    padding: 15px 50px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    backdrop-filter: blur(10px);
    transition: all 0.3s ease;
    cursor: pointer;
    text-decoration: none;
}

.btn.glass-btn:hover {
    background: rgba(101, 121, 110, 0.5); /* Darker shade on hover */
    color: #000;
}

/* Scroll Button */
.wrapper-scroll {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    text-align: center;
    z-index: 1;
}

.scroll-button-wrapper {
    padding-top: 10px;
    width: 50px;
    height: 50px;
    margin: 0 auto;
    border: 2px solid #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
}

.scroll-button-wrapper:hover {
    transform: translateY(-5px);
    border-color: #65796e; /* Applied theme color on hover */
}

.scroll-button-wrapper img {
    width: 24px;
    height: 24px;
}

/* Responsive Design */
@media screen and (max-width: 767px) {
    .hero-h1 {
        font-size: 3em;
    }

    .hero-p {
        font-size: 1.3em;
    }

    .btn.glass-btn {
        padding: 12px 35px;
        font-size: 0.9em;
    }
}

@media screen and (max-width: 479px) {
    .hero-wrapper {
        padding-top: 120px;
    }
}

.product-category-section {
    margin: 30px 0px;
    /* padding: 60px 20px; */
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    /* box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); */
}
.product-category-section .container .product-grid .product-link {
    display: block;
    text-decoration: none; /* Remove default underline */
    color: inherit; /* Inherit color from parent */
}
.product-category-section::before,
.product-category-section::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    opacity: 0.6;
    transform: scale(1);
    background: rgba(255, 255, 255, 0.08);
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.2), 
                0 0 30px rgba(255, 255, 255, 0.2);
    transition: transform 0.5s ease;
}

.product-category-section::before {
    width: 300px;
    height: 300px;
    top: -50px;
    left: -100px;
}

.product-category-section::after {
    width: 250px;
    height: 250px;
    bottom: -60px;
    right: -80px;
}

.product-category-section:hover::before,
.product-category-section:hover::after {
    transform: scale(1.2);
}

.container {
    position: relative;
    z-index: 2;
}



.product-grid{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.product-box {
    flex: 0 0 18%;
    width: 20%;
    margin-bottom: 20px;
    text-align: center;
    padding: 35px;
    background: rgba(255, 255, 255, 0.2); /* Transparent glass effect */
    backdrop-filter: blur(10px); /* Blur effect for glass */
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.3); /* Subtle border to match the transparency */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
    z-index: 2;
}

.product-box::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(22, 121, 110, 0.2), rgba(255, 255, 255, 0.2));
    opacity: 0;
    transition: opacity 0.3s ease;
}

.product-box:hover::before {
    opacity: 1;
}

.product-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}
.product-link {
    display: block;
    text-decoration: none; /* Remove default underline */
    color: inherit; /* Inherit color from parent */
    height: 100%; /* Ensure Link takes full height of parent */
    width: 100%; /* Ensure Link takes full width of parent */
    display: flex; /* Flexbox to center content */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
}

.product-content {
    text-align: center;
    backdrop-filter: blur(10px); /* Blur effect for glass */
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.3); /* Subtle border to match the transparency */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
}
.product-box .p-icon {
    font-size: 40px;
    color: #16796e;
    margin-bottom: 15px;
    transition: color 0.3s ease;
}

.product-box:hover .p-icon {
    color: #145d58;
}

.product-box h3 {
    color: #333;
    font-size: 16px;
    font-weight: 700;
    margin-top: 10px;
    text-transform: uppercase;
    transition: color 0.3s ease;
}

.product-box:hover h3 {
    color: #16796e;
}
@media screen and (max-width: 768px) {
    .product-box {
        padding: 20px;
        flex: 0 0 46%; /* Two items per row */
        width: 48%;
    }

    .product-content{
        padding: 10px 0px;
    }

    .product-content h3{
        text-align: center;
        font-size: 13px;
    }
}
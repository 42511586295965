.p-container{
  margin: 100px 0px;
}
.product-grid-section {
  margin: 50px 0px;
    padding: 60px 0; /* Increased padding for more space around the section */
    background-color: #f4f4f4; /* Slightly darker background for better contrast */
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .p-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 30px; /* Increased space between items */
  }
  
  .p-box {
    display: flex;
    border: 1px solid #ddd; /* Border color */
    border-radius: 12px; /* Slightly more rounded corners */
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow for depth */
    transition: box-shadow 0.3s ease, transform 0.3s ease, border-color 0.3s ease, background-color 0.3s ease;
    flex: 1 1 calc(50% - 30px); /* Adjusted for new gap */
    max-width: calc(50% - 30px);
    padding: 50px; /* Increased padding inside the box */
    position: relative; /* To position overlay */
  }
  
  .p-box:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* More pronounced shadow on hover */
    transform: translateY(-8px) scale(1.02); /* Slightly higher lift effect and scale */
    border-color: #16796e; /* Highlight border on hover */
    background-color: #f9f9f9; /* Subtle background color change on hover */
  }
  
  .p-img {
    flex: 0 0 40%;
    background-color: #e0e0e0; /* Slightly darker background */
    display: flex; /* Centering image */
    align-items: center;
    justify-content: center;
    padding: 10px; /* Padding inside the image container */
    border-radius: 8px; /* Rounded corners */
    position: relative;
    overflow: hidden; /* To ensure image fits within container */
    transition: background-color 0.3s ease; /* Smooth background color transition */
  }
  
  .p-img:hover {
    background-color: #d5d5d5; /* Slightly darker background on hover */
  }
  
  .p-img img {
    width: 100%; /* Full width of the image container */
    height: auto;
    display: block;
    border-radius: 8px; /* Rounded corners for images */
    transition: transform 0.4s ease-in-out; /* Smooth scale effect */
  }
  
  .p-img:hover img {
    transform: scale(1.1); /* Slightly zoomed-in effect on hover */
  }
  
  .p-con {
    flex: 1;
    padding: 20px;
    border-left: 2px solid #16796e; /* Adding a left border to highlight content */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space between title, description, and button */
    transition: padding 0.3s ease; /* Smooth padding transition */
  }
  
  .p-con h3 {
    margin-top: 0;
    font-size: 1.6rem; /* Slightly larger font size for titles */
    color: #16796e; /* Using theme color for title */
    font-weight: 600; /* Slightly bolder font for titles */
    transition: color 0.3s ease; /* Smooth color transition */
  }
  
  .p-con p {
    margin: 10px 0; /* Added margin for spacing above the paragraph */
    font-size: 1rem; /* Adjusted font size */
    color: #555; /* Slightly lighter text color */
    line-height: 1.6; /* Increased line height for better readability */
    transition: color 0.3s ease; /* Smooth color transition */
  }
  
  .p-box:hover .p-con h3,
  .p-box:hover .p-con p {
    color: #16796e; /* Change text color on hover */
  }
  
  .qoute-btn {
    background-color: #16796e; /* Theme color for button background */
    color: #fff; /* White text color */
    border: none;
    border-radius: 8px; /* Rounded corners */
    padding: 12px 24px; /* Increased padding inside the button */
    font-size: 1rem; /* Font size for button text */
    font-weight: 600; /* Slightly bolder text */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease, box-shadow 0.3s ease-in-out;
    text-align: center;
  }
  
  .qoute-btn:hover {
    background-color: #135d4f; /* Darker shade for hover effect */
    transform: translateY(-2px) scale(1.05); /* Subtle lift and scale effect on hover */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
  }
  
  .qoute-btn:focus {
    outline: 2px solid #135d4f; /* Outline on focus for accessibility */
  }


/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .p-box {
    flex: 1 1 calc(100% - 30px); /* Full width on smaller screens */
    flex-direction: column;
    max-width: calc(100% - 30px);
    padding: 30px; /* Reduce padding */
  }

  .p-img {
    flex: 0 0 50%; /* Image takes full width */
    margin-bottom: 20px; /* Space between image and content */
  }

  .p-con {
    padding: 20px 10px; /* Reduce padding inside the content */
    border-left: none; /* Remove the left border on smaller screens */
    border-top: 2px solid #16796e; /* Add a top border instead */
  }
  
  .p-con h3 {
    font-size: 1.4rem; /* Adjust font size */
  }
  
  .p-con p {
    font-size: 0.9rem; /* Adjust font size */
  }

  .qoute-btn {
    padding: 10px 20px; /* Adjust padding for buttons */
    font-size: 0.9rem; /* Adjust font size */
  }
}
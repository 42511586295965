.boxes-4{
    margin-top: 100px;
    margin-bottom: 100px;
}


.four-box{
    display: flex;
    padding: 20px;
    gap: 15px;
    justify-content: space-between;
}

.box-sec {
    flex: 1;
    height: 200px;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: box-shadow 0.3s ease;
}

.content-1 {
    padding: 20px;
    text-align: center;
}

.content-1 p {
    font-size: 16px;
    color: #333;
    line-height: 1.5;
    margin: 0;
}

@media (max-width: 768px) {
    .four-box {
        flex-direction: column;
    }
}
/* src/components/CTA2.css */
.cta-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 60px 0px; */
    /* background-color: #f3f3f3; */
    border-radius: 15px;
    /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); */
    overflow: hidden;
}

.contain-1 {
    width: 100%;
    max-width: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 20px;
    box-shadow: inset 0 0 0 1000px rgba(22, 121, 110, 0.6); /* Overlay effect */
}

.cta-content {
    text-align: center;
    color: #ffffff;
    z-index: 1;
    position: relative;
    max-width: 700px;
}

.cta-content h3 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.cta-content p {
    font-size: 1.25rem;
    margin-bottom: 30px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    line-height: 1.6;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.Btn {
    padding: 15px 30px;
    font-size: 1.2rem;
    color: #ffffff;
    background-color: #16796e;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    text-transform: uppercase;
    font-weight: 600;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.Btn:hover {
    background-color: #135e50;
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.Btn:focus {
    outline: none;
    background-color: #135e50;
    box-shadow: 0 0 0 4px rgba(22, 121, 110, 0.5);
}

@media (max-width: 768px) {
    .cta-2 {
        padding: 40px 20px;
    }

    .cta-content h3 {
        font-size: 2rem;
    }

    .cta-content p {
        font-size: 1rem;
    }

    .Btn {
        padding: 12px 25px;
        font-size: 1rem;
    }
}

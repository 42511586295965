/* Base styles for the footer */
.footer {
    width: 100%;
    padding: 20px 30px;
    background-color: #16796e;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 0;
    z-index: 1000;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Container for content alignment */
  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 10px;
  }
  
  /* Copyright text on the left */
  .copyright {
    font-size: 1em;
  }
  
  /* Privacy Policy link on the right */
  .privacy-policy {
    font-size: 1em;
  }
  
  .footer-link {
    padding: 5px;
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer-link:hover {
    color: #0f4c4c;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      text-align: center;
    }
  
    .copyright,
    .privacy-policy {
      margin-bottom: 10px;
    }
  
    .privacy-policy {
      margin-bottom: 0;
    }
  }
  
  @media (max-width: 480px) {
    .footer {
      padding: 15px 20px;
    }
  
    .footer-container {
      padding: 10px;
    }
  
    .copyright,
    .privacy-policy {
      font-size: 0.9em;
    }
  }
  
.wf-section{
    margin: 30px 0px;
}


.row{
    margin-right: -15px;
    margin-left: -15px;
}

.servicesa .content._33{
    width: 30%;
    padding: 10px;
    float: left;
}

.service-wrapper{
    height: 100%;
    padding: 40px 20px;
    border-top: 5px solid #000;
    background-color: #f5f5f5;
    border-radius: 20px;
    text-align: center;
    height: 400px;
}


.icon-wrapper{
    width: 60px;
    height: 60px;
    min-height: 60px;
    min-width: 60px;
    margin: 0px auto 20px;
    padding: 8px;
    border-radius: 360px;
    background-color: #238276;
}

.icon-wrapper img{
    background-size: cover;
    max-width: 100%;
}

.service-title{
    font-family: 'Poppins', sans-serif;
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 1.5em;
    border-radius: 20px;
    line-height: 120%;
    color: #16795e;
    font-weight: 600;
}

.p15{
    opacity: 0.8;
    font-size: 15px;
    line-height: 23px;
}

@media screen and (max-width: 768px){
    .servicesa .content._33{
        width: 100%;
        padding: 10px;
        float: left;
    }

}
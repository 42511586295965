.contactsol{
    background: #eee;
    padding: 25px 0px 35px;
}

.btns{
    padding: 16px 32px;
    font-size: 1.2rem;
    color: #ffffff;
    background-color: #16796e;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    outline: none;
}

.Btns{
    background-color: #135e50;
    transform: translateY(-3px);
}
.Btns:active{
    outline: 3px solid #135e50;

}

.contsis{
    background: #378e82;
    margin: 25px auto;
    color: #fff;
    padding: 10px 30px !important;
    display: table !important;
}

.contactlink{
    text-decoration: none;
}
/* Base styles */
* {
  box-sizing: border-box;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s, box-shadow 0.3s, padding 0.3s;
  z-index: 1000;
  /* background-color: rgba(255, 255, 255, 0.3); */
  /* backdrop-filter: blur(10px); */
}

.header.scrolled {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); 
  padding: 20px 40px;
}
/* Prevent sticky interference when mobile menu is open */
.header.menu-open {
  position: absolute;
}

/* Header container */
.header-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
}

/* Logo styling */
.logo {
  max-height: 60px;
}

/* Navigation bar */
.navBar {
  display: flex;
  align-items: center;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.navList {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navItems {
  margin: 0 20px;
}

.navLinks {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  font-size: 1.2em;
  transition: color 0.3s, background-color 0.3s, transform 0.3s;
  padding: 10px 20px;
  border-radius: 10px;
  position: relative;
}

.navLinks::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #16796e;
  transition: width 0.3s ease;
}

.hashLink::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #16796e;
  transition: width 0.3s ease;
}





.navLinks:hover::after {
  width: 100%;
  left: 0;
}

.header.scrolled .navLinks {
  color: #16796e; /* New color for menu items */
}

.header.scrolled .hashLink {
  color: #16796e; /* New color for menu items */
}

.navLinks:hover, .navLinks:focus {
  color: #16796e;
  transform: scale(1.05);
}

.navLinks.active {
  color: #16796e;
  transform: scale(1.1);
  /* border-bottom: 2px solid #ffffff; Optional: adds a border to the active link */
}
/* Hasing About and Brand */
.hashLink{
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  font-size: 1.2em;
  transition: color 0.3s, background-color 0.3s, transform 0.3s;
  padding: 10px 20px;
  border-radius: 10px;
  position: relative;
}

.hashLink::after{
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0;
  height: 2px;
  transition: width 0.3s ease;
}
.hashLink:hover::after {
  width: 100%;
  left: 0;
}

.hashLink:hover, .navLinks:focus {
  color: #16796e;
  transform: scale(1.05);
}

/* Hamburger menu */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0;
}

.hamburger .bar {
  background-color: #16796e;
  height: 4px;
  width: 35px;
  margin: 6px 0;
  transition: 0.4s;
}

/* Mobile styles */
@media (max-width: 768px) {
  .navBar {
    display: flex;
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    background-color: rgba(22, 121, 110, 0.9);
    width: 100%;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
    transform: translateY(-100%);
    transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
    opacity: 0;
    backdrop-filter: blur(10px);
    z-index: 1000;
  }

  .navBar.nav-open {
    transform: translateY(0);
    opacity: 1;
    z-index: 1001;
  }

  .navList {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .navItems {
    margin: 20px 0;
    width: 100%;
  }

  .navLinks {
    font-size: 1.5em;
    width: 100%;
    padding: 15px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.3s;
    color: #fff;
  }

  .navLinks:hover, .navLinks:focus {
    color: #333;
  }

  .hashLink {
    font-size: 1.5em;
    width: 100%;
    padding: 15px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.3s;
    color: #fff;
  }

  .hashLink:hover, .navLinks:focus {
    color: #333;
  }

  .hamburger {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 30px;
    right: 20px;
    z-index: 1002;
  }
  .logo{
    font-size: 1.5rem;
  } 

  /* Hide hamburger when menu is open */
  .hamburger.open {
    display: none;
  }

  .hamburger .bar {
    background-color: #16796e;
    height: 2px;
    width: 30px;
    margin: 4px 0;
    transition: 0.4s;
  }

  /* Cancel button */
  .cancelButton {
    align-self: flex-end;
    font-size: 1.5em;
    color: #fff;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 10px;
    transition: color 0.3s;
  }

  .cancelButton:hover {
    color: #0f4c4c;
  }

  .navLinks.active{
    color: #333;
  }
}

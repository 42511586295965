/* src/components/Faq/Faq.css */
.faq-section {
    /* background: linear-gradient(135deg, rgba(22, 121, 110, 0.7), rgba(22, 121, 110, 0.4)); */
    background-size: cover;
    background-position: center;
    padding: 60px 0;
    position: relative;
  }
  

.faq-container {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    border-radius: 12px; /* Rounded corners for container */
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3); /* Shadow for container */
  }
  
  .faq-item {
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.1); /* Slightly more transparent for glass effect */
    backdrop-filter: blur(20px); /* Smooth glass effect */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25); /* Depth shadow */
    margin-bottom: 20px;
    transition: box-shadow 0.4s ease-in-out, background 0.4s ease-in-out;
  }
  
  .faq-item:hover {
    background: rgba(255, 255, 255, 0.3); /* Darker glass effect on hover */
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.35); /* More pronounced shadow on hover */
  }
  
  .faq-question {
    cursor: pointer;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;
    font-size: 20px; /* Larger font for readability */
    font-weight: 600; /* Bolder font for emphasis */
    border-bottom: 1px solid rgba(255, 255, 255, 0.4); /* More visible border */
    position: relative; /* Required for pseudo-element positioning */
  }
  
  .faq-answer {
    padding: 20px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out; /* Smooth transitions */
    opacity: 0;
    color: #16796e; /* Lighter color for better readability */
  }
  
  .faq-answer.show {
    max-height: 1000px; /* Adjust based on content size */
    opacity: 1;
  }
  
  .faq-icon {
    font-size: 24px; /* Larger icon for better visibility */
    color: #16796e; /* Theme color for icon */
    transition: transform 0.5s ease-in-out; /* Smooth rotation */
  }
  
  .faq-icon.open {
    transform: rotate(45deg);
  }
  
  .faq-item:focus-within .faq-question {
    background: rgba(22, 121, 110, 0.5); /* Highlight background with theme color */
  }
  
  /* Optional: Add a decorative glassy bar on the left of the question */
  .faq-question::before {
    content: '';
    display: block;
    width: 6px;
    height: 100%;
    background-color: #16796e; /* Vertical bar with theme color */
    position: absolute;
    left: 0;
    top: 0;
    border-top-left-radius: 12px; /* Match rounded corners */
    border-bottom-left-radius: 12px;
  }
  
  .faq-answer p {
    margin: 0; /* Remove default margin for better alignment */
  }
  
/* src/components/CallToAction.css */
.cta-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px;
    /* background: linear-gradient(185deg, #e0f2f1 0%, #ffffff 100%); */
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    text-align: center;
    max-width: 100%;
    margin: 30px auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-container:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.cta-heading {
    font-size: 2.8rem;
    margin-bottom: 15px;
    color: #16796e;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-weight: 700;
    transition: color 0.3s ease;
}

.cta-text {
    font-size: 1.25rem;
    margin-bottom: 25px;
    color: #555;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    line-height: 1.6;
}

.cta-button {
    padding: 16px 32px;
    font-size: 1.2rem;
    color: #ffffff;
    background-color: #16796e;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    outline: none;
}

.cta-button:hover {
    background-color: #135e50;
    transform: translateY(-3px);
}

.cta-button:focus {
    outline: 3px solid #135e50;
}

@media (max-width: 768px) {
    .cta-container {
        padding: 40px;
    }

    .cta-heading {
        font-size: 2rem;
    }

    .cta-text {
        font-size: 1rem;
    }

    .cta-button {
        padding: 12px 24px;
        font-size: 1rem;
    }
}
